<template>
  <v-app>
    <HeaderBar />
    <v-container>
      <component :is="layout">
        <router-view></router-view>
      </component>
    </v-container>
    <AdminDial />
    <BottomNavigation />
  </v-app>
</template>

<script>
import BottomNavigation from './components/Layout/BottomNavigation.vue';
import HeaderBar from './components/Layout/HeaderBar.vue';
import AdminDial from './components/Layout/AdminDial.vue';
import RunSelector from '@/components/Results/RunSelector.vue';

import { mapActions, mapState } from 'vuex';

const default_layout = 'default';

export default {
  name: 'App',
  components: { BottomNavigation, RunSelector, HeaderBar, AdminDial },
  methods: {
    ...mapActions('runs', ['bindRuns']),
    ...mapActions('players', ['bindPlayers']),
    ...mapActions('auth', ['watchLogin']),
  },
  computed: {
    ...mapState('runs', ['counts']),
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
  },
  created() {
    this.bindRuns();
    this.bindPlayers();
    this.watchLogin();
  },
};
</script>
