<template>
  <div>
    <v-app-bar class="header-bar" color="transparent" elevation="0" dense>
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-toolbar-title> {{ page }}</v-toolbar-title>
      <v-dialog v-if="!isLogged" v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab absolute right icon x-small>
            <v-icon>mdi-account-tie</v-icon></v-btn
          >
        </template>
        <AuthForm />
      </v-dialog>
      <div
        v-if="isLogged"
        style="display: flex;
    justify-content: flex-end;
    flex: 1 1 auto;"
      >
        <v-btn text rounded right :to="{ name: 'Admin' }">Admin</v-btn>
        <v-menu
          absolute
          right
          v-if="isLogged"
          v-model="showMenu"
          offset-y
          style="max-width: 600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="adminbtn"
              outlined
              v-bind="attrs"
              v-on="on"
              fab
              icon
              x-small
            >
              <v-icon>mdi-account-tie</v-icon></v-btn
            >
          </template>

          <v-list>
            <!-- <v-list-item :to="{ name: 'Admin' }">
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item> -->
            <v-list-item @click="logout">
              <v-list-item-title>Déconnexion</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>
<style>
.adminbtn {
  margin: 0 5px;
}
.v-toolbar__content {
  border-bottom: 1px solid #e8e8e8;
}
</style>
<script>
import { mapActions, mapState } from 'vuex';
import AuthForm from '@/components/Login/AuthForm';

export default {
  components: { AuthForm },
  data() {
    return { dialog: false, showMenu: false };
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
  computed: {
    ...mapState(['page']),
    ...mapState('auth', ['isLogged']),
  },
};
</script>
