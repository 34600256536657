<template>
  <section>
    <!-- <v-row>
      <v-col cols="12">
        <RunSelector @updateDates="changeSelectedRun" />
      </v-col>
    </v-row> -->
    <v-row cols="12">
      <v-col>
        <GlobalBumpsResult :global="bumpsGlobalCount" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <ScoreChart :chartData="scoreDatasChart" />
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <ScoreChart :chartData="clapDatasChart"
      /></v-col>
    </v-row>
    <v-divider />
  </section>
</template>
<script>
import ScoreChart from '@/components/Results/graphs/ScoreChart.js';
import GlobalBumpsResult from '@/components/Results/GlobalBumpsResult';
// eslint-disable-next-line no-unused-vars
import dayjs from 'dayjs';
import DatesRangeSelector from './DatesRangeSelector.vue';

import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  components: {
    ScoreChart,
    GlobalBumpsResult,
    // eslint-disable-next-line vue/no-unused-components
    DatesRangeSelector,
  },
  data() {
    return {
      dates: [
        new Date(),
        new Date(
          dayjs()
            .add(14, 'day')
            .toDate()
        ),
      ],
    };
  },
  methods: {
    ...mapActions('runs', ['setSelectedRun']),
    changeDatesRange(dates) {
      this.dates = dates.map((d) => {
        const date = new Date(d);
        date.setHours(0, 0);
        return date;
      });
    },

    orderData(counts, type = 'score') {
      const compare = (a, b) => {
        return b[type] - a[type];
      };
      return [...counts].sort(compare);
    },
    datasChart(name, type = 'score') {
      const counts = this.orderData(this.counts, type).filter(
        (c) => c[type] !== 0
      );

      const datas = counts.map((c) => c[type]);
      const labels = counts.map((c) => c.surname);
      const chartData = {
        labels: [...labels],
        datasets: [
          {
            label: name,
            backgroundColor: '#f87979',
            data: [...datas],
          },
        ],
      };
      return chartData;
    },
  },
  computed: {
    ...mapState('runs', ['counts', 'bumps']),
    ...mapGetters('runs', ['bumpsGlobalCount']),
    ...mapState('auth', ['isLogged']),
    scoreDatasChart() {
      return this.datasChart('Score', 'score');
    },
    clapDatasChart() {
      return this.datasChart('Clapiste', 'clap');
    },
  },
};
</script>
