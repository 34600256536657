<template>
  <div class="bump-item-container">
    <div class="img-bumper" :style="{ background: color }">
      <v-icon medium color="amber">
        mdi-account
      </v-icon>
    </div>
    <div
      class="main-content"
      :class="{
        successOutline: bump.success,
        failOutline: !bump.success && bump.trolled,
        trollOutline: !bump.success && !bump.trolled,
      }"
    >
      <span class="pseudo" v-if="bumper"> {{ bumper.surname }}</span>
      <div :class="'bump-' + (bump.success ? 'success' : 'fail')">
        <v-icon color="light-green" left v-if="bump.success">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" left v-if="!bump.success && !bump.trolled">
          mdi-close-circle
        </v-icon>
        <v-icon color="orange" left v-if="!bump.success && bump.trolled">
          mdi-close-circle
        </v-icon>
      </div>
      <div class="clapiste" v-if="bump.success && (clapiste || isLogged)">
        <v-icon v-if="clapiste" x-small color="amber">
          mdi-human-handsup
        </v-icon>
        <span v-if="clapiste"> {{ clapiste.surname }}</span>
        <v-dialog v-if="isLogged" v-model="dialog" max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon x-small v-if="!clapiste" v-bind="attrs" v-on="on">
              <v-icon x-small color="amber">
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-form>
                    <v-select
                      label="Clapiste"
                      :items="list"
                      v-model="newClapiste"
                      hide-details
                      prepend-icon="mdi-account"
                      required
                      item-text="surname"
                      item-value="id"
                    ></v-select>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn block @click="updateClapiste">save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="date">
        {{ date | formatDate('DD/MM', true) }}
        <template v-if="isLogged">
          à
          {{ date | formatDate('HH:mm', true) }}
        </template>
        <template v-else>
          vers
          {{ date | formatDate('HH', true) }}h
        </template>
      </div>
    </div>

    <div v-if="isLogged" class="admin-actions">
      <!-- <v-btn color="red" elevation="0" fab x-small>
        <v-icon color="white">
          mdi-delete
        </v-icon>
      </v-btn> -->
      <v-btn elevation="0" fab x-small @click="edit = true">
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
    <v-dialog v-if="isLogged" v-model="edit" max-width="400">
      <BumpForm :bump="bump" mode="edit" @updated="edit = false" />
    </v-dialog>
  </div>
</template>
<style lang="scss">
.bump-item-container {
  display: flex;
  position: relative;
  max-width: 320px;
  margin-bottom: 20px;
  margin: auto;
  .img-bumper {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    overflow: hidden;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-content {
    position: relative;
    background: #e8e8e8;
    width: 100%;
    align-self: flex-end;
    display: flex;
    padding: 5px 0 5px 35px;
    margin-left: -25px;
    border-radius: 0 40px 40px 0;
    &.successOutline {
      border-bottom: 2px solid #8bc34a;
      // box-shadow: 0 2px 10px rgba(139, 195, 74, 0.26);
    }
    &.failOutline {
      border-bottom: 2px solid #ff9800;
      // box-shadow: 0 2px 10px rgba(255, 153, 0, 0.26);
    }
    &.trollOutline {
      border-bottom: 2px solid #f44336;
      // box-shadow: 0 2px 10px rgba(244, 67, 54, 0.26);
    }
    .pseudo {
      flex: 1 1 auto;
    }
  }
  .date {
    position: absolute;
    font-size: 0.9em;
    bottom: 93%;
    right: 5px;
  }
  .clapiste {
    position: absolute;
    top: 90%;
    right: 30px;
    display: flex;
    align-items: center;
    background: #292929;
    color: white;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 0.7em;
  }
  .admin-actions {
    display: flex;
    align-items: center;
    margin-left: 5px;
    transform: translateY(4px);
    .v-btn {
      margin-right: 2px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
</style>

<script>
import 'firebase/firestore';
import { mapState } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { db } from '@/store/firebase';

// import firebase from 'firebase/app';
import BumpForm from '@/components/BumpsList/BumpForm';
export default {
  name: 'BumpItem',
  components: { BumpForm },
  props: ['bump'],
  data() {
    return {
      dialog: false,
      newClapiste: null,
      edit: false,
    };
  },
  computed: {
    ...mapState('players', ['list']),
    ...mapState('auth', ['isLogged']),
    color() {
      if (
        this.bumper &&
        typeof this.bumper === 'object' &&
        'discordTag' in this.bumper
      ) {
        const tag = this.bumper.discordTag.split('#')[1];
        let endColor = parseInt(tag.slice(0, 2)) + parseInt(tag.slice(2));
        if (endColor >= 100) {
          endColor = Number(endColor).toString(16);
          // endColor = parseInt(endColor.slice(0, 2)) + parseInt(endColor[2]);
        } else if (endColor < 10) {
          endColor = endColor + 60;
        }
        return '#' + tag + endColor;
      } else {
        return '#ffeecc';
      }
    },
    bumper() {
      return this.bump.bumper;
    },
    clapiste() {
      return this.bump.clapiste;
    },
    date() {
      if (this.bump.datetime) {
        return this.bump.datetime.seconds;
      }
      return new Date();
    },
  },
  methods: {
    updateClapiste() {
      db.collection('bumps')
        .doc(this.bump.id)
        .update({
          clapiste: db.collection('bumpers').doc(this.newClapiste),
        });
      this.dialog = false;
    },
  },
};
</script>
