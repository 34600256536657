<template>
  <v-card>
    <v-card-title>Ajouter une course</v-card-title>
    <v-form @submit="addRun">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field outlined v-model="name" label="Nom de la course">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-menu
              v-model="selectDateStart"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="dateStart"
                  label="Date de début"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateStart"
                @input="selectDateStart = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-menu
              v-model="selectDateEnd"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="dateEnd"
                  label="Date de fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateEnd"
                @input="selectDateEnd = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-btn outlined rounded block type="submit">Save</v-btn>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { db } from '@/store/firebase';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  data() {
    return {
      name: '',
      selectDateStart: false,
      selectDateEnd: false,
      dateStart: dayjs().format('YYYY-MM-DD'),
      dateEnd: dayjs()
        .add(2, 'week')
        .format('YYYY-MM-DD'),
    };
  },
  methods: {
    addRun(e) {
      e.preventDefault();
      db.collection('bumpsRuns')
        .add({
          name: this.name,
          dateStart: firebase.firestore.Timestamp.fromDate(
            new Date(this.dateStart)
          ),
          dateEnd: firebase.firestore.Timestamp.fromDate(
            new Date(this.dateEnd)
          ),
        })
        .then(() => {
          this.$emit('added', true);
        });
    },
  },
};
</script>
