import firebase from 'firebase/app';
import 'firebase/auth';
// import { vuexfireMutations } from 'vuexfire'; // create bug

const initialState = () => {
  return { user: null, error: null, isLogged: false };
};

export const Auth = {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      return state;
    },
    SET_IS_LOGGED(state, payload) {
      state.isLogged = payload;
      return state;
    },
  },
  actions: {
    login({ commit }, payload) {
      console.log('LOGIN');
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then((userCredential) => {
          const user = userCredential.user;
          commit('SET_USER', user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
        });
    },
    async logout({ commit }) {
      await firebase.auth().signOut();
      commit('SET_IS_LOGGED', false);
      commit('SET_USER', null);
    },
    watchLogin({ commit }) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          commit('SET_IS_LOGGED', true);
          commit('SET_USER', user);
        } else {
          commit('SET_IS_LOGGED', false);
          commit('SET_USER', null);
        }
      });
    },
  },
  modules: {},
};
