<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-menu
          v-model="selectDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          hide-details
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Dates"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            @change="datesChanged"
            v-model="dates"
            range
            hide-details
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    dates: [],
    bumpsRuns: {},
    selectDate: false,
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    datesChanged(item) {
      this.selectDate = false;
      this.$emit('updateRange', item);
    },
  },
};
</script>
