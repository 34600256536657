<template>
  <v-container>
    <v-row><v-col>permettre d'activé / désactivé une run, </v-col></v-row>
    <v-row>
      <v-tabs v-model="tab">
        <v-tab>Joueurs</v-tab>
        <v-tab>Bumpwar</v-tab>
        <v-tab>Autre ?</v-tab>
      </v-tabs>
      <v-col cols="12"><FixUserId /></v-col>
      <v-col cols="12" sm="6" md="4"><BumpForm /></v-col>
      <v-col cols="12" sm="6" md="4"><BumperForm /></v-col>
      <v-col cols="12" sm="6" md="4"><BumpsRunsForm /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import BumperForm from '@/components/Bumpers/BumperForm';
import BumpsRunsForm from '@/components/BumpsRuns/BumpsRunsForm';
import BumpForm from '@/components/BumpsList/BumpForm';
import FixUserId from '@/components/Admin/FixUserId';
export default {
  components: { BumperForm, BumpsRunsForm, BumpForm, FixUserId },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    this.$store.dispatch('setPageName', 'Admin');
  },
};
</script>
