import Vue from 'vue';
import dayjs from 'dayjs';

Vue.filter('formatDate', function(
  value,
  format = 'DD/MM/YYYY',
  timestamp = false
) {
  if (value && !timestamp) {
    return dayjs(value).format(format);
  }
  if (value && timestamp) {
    return dayjs.unix(value).format(format);
  }
});
