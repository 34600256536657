import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['chartData'],
  data() {
    return {
      options: {
        responsive: true,
        ticks: {
          beginAtZero: true,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: 10,
                stepSize: 1,
                reverse: false,
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: function(context) {
              return context.dataset.data[context.dataIndex] > 0; // or >= 1 or ...
            },
          },
        },
      },
    };
  },
  mounted() {
    if (!this.chartData) return;
    console.log(this.options);
    this.renderChart(this.chartData, this.options);
  },
};
