import { db } from '@/store/firebase';
import { vuexfireMutations, firestoreAction } from 'vuexfire';

export const Players = {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: vuexfireMutations,
  actions: {
    bindPlayers: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'list',
        db.collection('bumpers').orderBy('surname', 'asc')
      );
    }),
  },
  getters: {},
};
