<template>
  <v-form v-if="list" @submit.prevent="save">
    <v-select
      outlined
      label="User"
      :items="list"
      v-model="user"
      hide-details
      prepend-icon="mdi-account"
      required
      item-text="surname"
      item-value="id"
      clearable
    ></v-select>
    <v-text-field label="New Id" v-model="id"></v-text-field>
    <v-btn type="submit">Add discord Id</v-btn>
  </v-form>
</template>
<script>
import { mapState } from 'vuex';
import { db } from '@/store/firebase';

export default {
  data() {
    return {
      user: '',
      id: '',
    };
  },
  methods: {
    save() {
      console.log(this.user);
      db.collection('bumpers')
        .doc(this.user)
        .update({
          discordId: this.id,
        })
        .then(() => {
          console.log('user id added');
        });
    },
  },
  computed: {
    ...mapState('players', ['list']),
  },
};
</script>
