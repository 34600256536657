import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { firestorePlugin } from 'vuefire';
import vuetify from './plugins/vuetify';
import './plugins/dateFilter';
import DefaultLayout from './components/Layout/DefaultLayout';
import AdminLayout from './components/Layout/AdminLayout';

Vue.config.productionTip = false;

Vue.use(firestorePlugin);
Vue.component('default-layout', DefaultLayout);
Vue.component('admin-layout', AdminLayout);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
