/* eslint-disable no-unused-vars */
import { db } from '@/store/firebase';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import dayjs from 'dayjs';

const BUMP_STATUS = {
  SUCCESS: 1,
  FAIL: 2,
  TROLL: 3,
};

export const Runs = {
  namespaced: true,
  state: () => ({
    list: [],
    bumps: [],
    selectedRun: null,
    counts: [],
  }),
  mutations: {
    ...vuexfireMutations,
    SELECT_RUN(state, run) {
      state.selectedRun = run;
      return state;
    },
    SET_DEFAULT_RUN(state) {
      if (!state.list || state.list.length === 0 || state.selectedRun)
        return state;
      state.selectedRun = state.list[0];
      return state;
    },
    SET_STATS(state) {
      const { bumps } = state;
      let counts = [];
      let bumpers = [];
      bumps.map((bump) => {
        if (!bumpers.includes(bump.bumper.surname) && bump.bumper.surname) {
          bumpers.push(bump.bumper.surname);
          counts.push(countbyBumper(bump.bumper.surname, bumps));
        }
        if (
          'clapiste' in bump &&
          bump.clapiste &&
          !bumpers.includes(bump.clapiste.surname) &&
          bump.clapiste.surname
        ) {
          bumpers.push(bump.clapiste.surname);
          counts.push(countbyBumper(bump.clapiste.surname, bumps));
        }
      });
      state.counts = counts;
      return state;
    },
  },
  actions: {
    bindBumps: firestoreAction(async ({ bindFirestoreRef, commit, state }) => {
      const res = await bindFirestoreRef(
        'bumps',
        db
          .collection('bumps')
          .orderBy('datetime', 'desc')
          .where('datetime', '>=', state.selectedRun.dateStart)
          .where('datetime', '<=', state.selectedRun.dateEnd)
      );
      commit('SET_STATS');
    }),
    bindRuns: firestoreAction(
      async ({ bindFirestoreRef, commit, dispatch }) => {
        await bindFirestoreRef(
          'list',
          db.collection('bumpsRuns').orderBy('dateStart', 'desc')
        );
        commit('SET_DEFAULT_RUN');
        dispatch('bindBumps');
      }
    ),
    setSelectedRun: ({ commit, dispatch }, run) => {
      commit('SELECT_RUN', run);
      dispatch('bindBumps');
    },
  },
  getters: {
    isCurrent({ selectedRun }) {
      if (!selectedRun) return null;
      if (dayjs(selectedRun.dateEnd.toDate()).diff(dayjs(), 'day', true) > 0) {
        return true;
      } else {
        return false;
      }
    },
    getLastSuccessfullBump({ bumps }) {
      let lastSuccessfullBump = null;
      let bumpCursor = 0;
      while (lastSuccessfullBump === null && bumpCursor < bumps.length) {
        if (bumps[bumpCursor].success) {
          lastSuccessfullBump = bumps[bumpCursor];
          break;
        }
        bumpCursor++;
      }
      return lastSuccessfullBump;
    },
    getBumperCount({ counts }) {
      return counts.reduce((acc, val) => {
        if (val.success > 0 || val.fail > 0 || val.troll > 0) return acc + 1;
        return acc;
      }, 0);
    },
    getClapisteCount({ counts }) {
      return counts.reduce((acc, val) => {
        if (val.clap > 0) return acc + 1;
        return acc;
      }, 0);
    },
    bumpsGlobalCount({ bumps }) {
      // console.table(bumps);
      if (!bumps || bumps.length === 0) return;
      const reducer = (acc, bump) => {
        if (bump.success) acc.success++;
        if (!bump.success) {
          if (bump.trolled) acc.troll++;
          else acc.fail++;
        }
        return acc;
      };
      const globalCount = bumps.reduce(reducer, {
        success: 0,
        fail: 0,
        troll: 0,
      });
      return globalCount;
    },
  },
};

const countbyBumper = (bumperName, bumpsList) => {
  const bumpsCount = {
    surname: bumperName,
    success: 0,
    fail: 0,
    troll: 0,
    score: 0,
    clap: 0,
  };
  bumpsList.map((bump) => {
    if (bump.bumper.surname !== bumperName) {
      if (
        'clapiste' in bump &&
        bump.clapiste &&
        bump.clapiste.surname === bumperName
      ) {
        bumpsCount.clap += 1;
      }
    }
    if (bump.bumper.surname === bumperName) {
      switch (getBumpStatus(bump)) {
        case BUMP_STATUS.SUCCESS:
          bumpsCount.success += 1;
          break;
        case BUMP_STATUS.FAIL:
          bumpsCount.fail += 1;
          break;
        case BUMP_STATUS.TROLL:
          bumpsCount.troll += 1;
          break;
        default:
          break;
      }
    }
  });
  bumpsCount.score = countPoints(bumpsCount);
  return bumpsCount;
};
const countPoints = (bumpsCount) => {
  return bumpsCount.success - bumpsCount.troll - bumpsCount.fail;
};
const getBumpStatus = (bump) => {
  if (bump.success) return BUMP_STATUS.SUCCESS;
  if (!bump.success) {
    if (bump.trolled) return BUMP_STATUS.TROLL;
    else return BUMP_STATUS.FAIL;
  }
};
