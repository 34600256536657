<template>
  <v-card>
    <v-card-title v-if="mode === 'edit'">Editer le bump</v-card-title>
    <v-card-title v-else>Ajouter un bump</v-card-title>
    <v-form @submit="addBump">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              outlined
              label="Bumper"
              :items="bumpers"
              v-model="bumper"
              hide-details
              prepend-icon="mdi-account"
              required
              item-text="surname"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              outlined
              prepend-icon="mdi-star"
              hide-details
              v-model="status"
              :items="[
                { text: 'success', value: true },
                { text: 'fail', value: false },
              ]"
              label="Statut"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="selectDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="date"
                  label="Date du bump"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                outlined
                v-model="date"
                @input="selectDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="selectTime"
              v-model="selectTime"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="time"
                  label="Heure"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                outlined
                v-if="selectTime"
                v-model="time"
                full-width
                format="24hr"
                @click:minute="$refs.selectTime.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-checkbox v-model="trolled" label="Trollé"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              outlined
              label="Clapiste"
              :items="bumpers"
              v-model="clapiste"
              hide-details
              prepend-icon="mdi-account"
              required
              item-text="surname"
              item-value="id"
              clearable
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn outlined rounded block type="submit">Save</v-btn></v-card-actions
      >
    </v-form>
  </v-card>
</template>
<script>
import { db } from '@/store/firebase';
import dayjs from 'dayjs';
import 'firebase/firestore';
import firebase from 'firebase/app';

// @ is an alias to /src
export default {
  name: 'BumpsForm',
  props: ['bump', 'mode'],
  data() {
    var d = new Date();
    console.log(this.bump);
    let time = d.getHours() + ':' + d.getMinutes();
    let date = d.toISOString().substr(0, 10);
    let status = true;
    let trolled = false;
    let bumper = '';
    let clapiste = '';
    if (this.bump && this.mode === 'edit') {
      console.log(this.bump);
      date = dayjs(this.bump.datetime.toDate()).format('MM-DD-YYYY');
      time = dayjs(this.bump.datetime.toDate()).format('HH:mm');
      status = this.bump.success;
      trolled = this.bump.trolled;
      if ('clapiste' in this.bump && this.bump.clapiste) {
        clapiste = this.bump.clapiste.id;
      }
      if ('bumper' in this.bump) {
        bumper = this.bump.bumper.id;
      }
    }
    return {
      bumpers: [],
      clapiste,
      selectTime: false,
      selectDate: false,
      status,
      trolled,
      date,
      bumper,
      time,
      // ...this.bump,
    };
  },
  methods: {
    addBump(e) {
      console.log('before the storm');

      e.preventDefault();
      if (this.status) {
        this.trolled = false;
      }
      const date = dayjs(this.date + ' ' + this.time, 'MM-DD-YYYY HH:mm');
      const clapiste = this.clapiste
        ? db.collection('bumpers').doc(this.clapiste)
        : null;
      const bump = {
        success: this.status,
        trolled: this.trolled,
        datetime: firebase.firestore.Timestamp.fromDate(
          new Date(date.toDate())
        ),
        bumper: db.collection('bumpers').doc(this.bumper),
        clapiste,
      };

      if (this.mode === 'edit') {
        db.collection('bumps')
          .doc(this.bump.id)
          .update(bump)
          .then(() => {
            this.$emit('updated', true);
          });
      } else {
        db.collection('bumps')
          .add(bump)
          .then(() => {
            // var d = new Date();
            this.status = true;
            this.bumper = '';
            // this.time = d.getHours() + ':' + d.getMinutes();
            this.trolled = false;
            this.$emit('added', true);
          });
      }
    },
  },
  firestore: {
    bumpers: db.collection('bumpers'),
  },
};
</script>
