<template>
  <div>
    <v-row>
      <v-col>
        <div class="container-last-bump">
          <h3 style="text-align:center">Dernier bump</h3>
          <BumpItem
            v-if="getLastSuccessfullBump"
            :bump="getLastSuccessfullBump"
          />
          <span v-if="isCurrent" class="nextBump"
            >Prochain bump à
            <strong v-if="isLogged">{{
              nextBump | formatDate('HH:mm')
            }}</strong>
            <strong v-else>
              ??:??
              <!-- <v-icon color="white">mdi-robot-confused</v-icon> -->
            </strong>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CurrentBumpRun />
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.nextBump {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;
  text-align: center;
  display: block;
  background: #607d8b;
  color: white;
  border-radius: 40px;
  margin: auto;
  width: 250px;
  padding: 3px 25px;
  font-size: 0.9em;
  box-shadow: -10px 20px 0px #e8e8e8;
}
.container-last-bump {
  position: relative;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 25px 10px 35px 10px;
  box-shadow: -10px 20px 0px #e8e8e8;
  margin-bottom: 20px;
  > h3 {
    position: absolute;
    display: block;
    padding: 5px;
    background: white;
    top: 0;
    left: 20px;
    transform: translateY(-50%);
  }
}
</style>
<script>
import dayjs from 'dayjs';
import BumpItem from '@/components/BumpsList/BumpItem';
import CurrentBumpRun from '@/components/BumpsRuns/CurrentBumpRun';
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('setPageName', 'Dashboard');
  },
  components: { BumpItem, CurrentBumpRun },
  computed: {
    ...mapState('runs', {
      selectedRun: (state) => state.selectedRun,
      dateStart: (state) =>
        state.selectedRun && state.selectedRun.dateStart.toDate(),
      dateEnd: (state) =>
        state.selectedRun && state.selectedRun.dateEnd.toDate(),
    }),
    ...mapState('auth', ['isLogged']),
    ...mapGetters('runs', [
      'getBumperCount',
      'getClapisteCount',
      'bumpsGlobalCount',
      'getLastSuccessfullBump',
      'isCurrent',
    ]),

    timeLeft() {
      if (!this.dateEnd) return null;
      const dayLeft = dayjs(this.dateEnd).diff(dayjs(), 'day');
      const hoursLeft = dayjs(this.dateEnd).diff(
        dayjs().add(dayLeft, 'day'),
        'hours'
      );
      const minutesLeft = Math.ceil(
        dayjs(this.dateEnd).diff(
          dayjs()
            .add(dayLeft, 'day')
            .add(hoursLeft, 'hour'),
          'minute',
          true
        )
      );
      // const dayLeft =dayjs(this.dateEnd).diff(dayjs(), 'day');
      return { days: dayLeft, hours: hoursLeft, minutes: minutesLeft };
    },

    nextBump() {
      if (this.getLastSuccessfullBump) {
        return dayjs(this.getLastSuccessfullBump.datetime.toDate()).add(
          2,
          'hour'
        );
      }
      return null;
    },
  },
};
</script>
