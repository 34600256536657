<template>
  <v-container>
    <v-row>
      <v-col>players</v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('setPageName', 'Bumpers & clapistes');
  },
};
</script>
