<template>
  <ul>
    <li v-for="bump in list" :key="bump['.key']">
      <BumpItem :bump="bump" />
    </li>
  </ul>
</template>
<style lang="scss" scoped>
ul {
  padding: 0;
  margin: auto;
  max-width: 300px;
}
li {
  margin-bottom: 20px;
  list-style: none;
}
</style>
<script>
import BumpItem from '@/components/BumpsList/BumpItem';

export default {
  name: 'BumpsList',
  components: { BumpItem },
  props: ['list'],
  data() {
    return {
      bumps: {},
    };
  },
};
</script>
