<template>
  <v-card>
    <v-card-title>Ajouter un bumper</v-card-title>
    <v-container>
      <v-form @submit="addBumper">
        <v-row>
          <v-col cols="12" sm="6"
            ><v-text-field
              outlined
              v-model="discordTag"
              name="discordTag"
              label="discordTag"
              hide-details
          /></v-col>
          <v-col cols="12" sm="6"
            ><v-text-field
              outlined
              v-model="discordId"
              name="discordId"
              label="discordId"
              hide-details
          /></v-col>
          <v-col cols="12" sm="6"
            ><v-text-field
              outlined
              v-model="surname"
              name="surname"
              label="surname"
              hide-details
          /></v-col>
        </v-row>
        <v-btn outlined rounded block type="submit">Save</v-btn>
      </v-form>
    </v-container>
  </v-card>
</template>
<script>
import { db } from '@/store/firebase';

export default {
  name: 'BumperForm',
  components: {},
  data() {
    return {
      discordTag: '',
      surname: '',
      discordId: '',
    };
  },
  methods: {
    addBumper(e) {
      e.preventDefault();
      this.discordTag = this.discordTag.replace(' ', '');
      db.collection('bumpers')
        .doc(this.discordTag.replace(/[;:$#@_-]|\.|\[|\$|\\`|\/|\s|\]/g, ''))
        .set({
          discordTag: this.discordTag,
          discordId: this.discordId,
          surname: this.surname,
          avatar: null,
        })
        .then(() => {
          this.surname = '';
          this.discordTag = '';
          this.discordId = '';
          this.$emit('added', true);
        });
    },
  },
};
</script>
