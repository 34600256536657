import Vue from 'vue';
import Vuex from 'vuex';
import { Players } from './player';
import { Runs } from './runs';
import { vuexfireMutations } from 'vuexfire';
import { Auth } from './auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    page: 'Dashboard',
  },
  mutations: {
    ...vuexfireMutations,
    SET_PAGE_NAME(state, page) {
      state.page = page;
      return state;
    },
  },
  actions: {
    setPageName({ commit }, page) {
      commit('SET_PAGE_NAME', page);
    },
  },
  modules: {
    runs: Runs,
    players: Players,
    auth: Auth,
  },
});
