<template>
  <v-card class="overflow-hidden mx-auto" height="60">
    <v-bottom-navigation height="60" app grow :value="value" color="blue-grey">
      <v-btn @click="value = 0" :to="{ name: 'Dashboard' }" text height="60">
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn @click="value = 1" :to="{ name: 'Bumps' }" text height="60">
        <span>Bumps</span>

        <v-icon>mdi-sword-cross</v-icon>
      </v-btn>

      <v-btn @click="value = 2" :to="{ name: 'Ranks' }" text height="60">
        <span>Ranks</span>

        <v-icon>mdi-poll</v-icon>
      </v-btn>

      <!-- <v-btn @click="value = 3" :to="{ name: 'Players' }" text height="60">
        <span>Joueurs</span>

        <v-icon>mdi-human-queue</v-icon>
      </v-btn> -->
      <!-- <v-btn @click="value = 4" :to="{ name: 'Admin' }" text height="60">
        <span>Admin</span>

        <v-icon>mdi-account-tie</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
  </v-card>
</template>
<script>
export default {
  data: () => ({ value: 0 }),
};
</script>
