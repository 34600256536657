<template>
  <v-select
    v-if="isLogged"
    label="Course aux bumps"
    :items="runs"
    hide-details
    v-model="selectedRun"
    prepend-icon="mdi-calendar"
    required
    item-text="name"
    @change="runChanged"
    return-object
  ></v-select>
</template>
<script>
import { db } from '@/store/firebase';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      runs: [],
      selectedRun: null,
    };
  },
  computed: {
    ...mapState('auth', ['isLogged']),
  },
  methods: {
    ...mapActions('runs', ['setSelectedRun']),
    runChanged(run) {
      this.selectedRun = run;
      this.setSelectedRun(run);
      // this.$emit('updateDates', run);
    },
  },
  created() {
    db.collection('bumpsRuns')
      .orderBy('dateStart', 'desc')
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => doc.data());
        this.runs = documents;
        this.runChanged(documents[0]);
      });
  },
  watch: {},
};
</script>
