<template>
  <div v-if="isLogged">
    <v-speed-dial
      class="admin-fab-button"
      v-model="fab"
      bottom
      right
      direction="left"
      fixed
      transition="slide-x-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-cog
          </v-icon>
        </v-btn>
      </template>
      <v-btn @click="openDialog('bump')" fab dark small color="green">
        <v-icon>mdi-sword-cross</v-icon>
      </v-btn>
      <v-btn @click="openDialog('bumper')" fab dark small color="indigo">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn @click="openDialog('run')" fab dark small color="red">
        <v-icon>mdi-run</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-dialog v-model="dialog" max-width="400">
      <BumpForm @added="dialog = false" v-if="dialogform === 'bump'" />
      <BumperForm @added="dialog = false" v-if="dialogform === 'bumper'" />
      <BumpsRunsForm @added="dialog = false" v-if="dialogform === 'run'" />
    </v-dialog>
  </div>
</template>
<style>
.admin-fab-button.v-speed-dial--bottom {
  bottom: 75px;
}
</style>
<script>
import BumpForm from '../BumpsList/BumpForm.vue';
import BumperForm from '@/components/Bumpers/BumperForm';
import BumpsRunsForm from '@/components/BumpsRuns/BumpsRunsForm';
import { mapState } from 'vuex';
export default {
  components: { BumpForm, BumperForm, BumpsRunsForm },
  data: () => ({
    fab: false,
    dialog: false,
    dialogform: null,
  }),
  methods: {
    openDialog(form) {
      console.log('open dialog', form);
      this.dialog = true;
      this.dialogform = form;
    },
  },
  computed: {
    ...mapState('auth', ['isLogged']),
  },
};
</script>
