<template>
  <v-card>
    <v-card-title>Se connecter</v-card-title>
    <v-card-subtitle
      >Bas les pattes si t'es pas Silène - Signé : la régie</v-card-subtitle
    >
    <v-card-text>
      <v-form>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              hide-details="auto"
              outlined
              label="email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="password"
              hide-details="auto"
              counter
              label="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn block rounded outlined @click="save">
        Log In
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return { show: false, password: '', email: '' };
  },
  methods: {
    ...mapActions('auth', ['login']),
    save() {
      this.login({ email: this.email, password: this.password });
    },
  },
};
</script>
