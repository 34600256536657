<template>
  <v-container>
    <!-- <v-row>
      <v-col>ranks</v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12"><RunResults /></v-col>
    </v-row>
  </v-container>
</template>
<script>
import RunResults from '@/components/Results/RunResults.vue';

export default {
  components: { RunResults },
  created() {
    this.$store.dispatch('setPageName', 'Resultats');
  },
};
</script>
