<template>
  <v-container>
    <v-row>
      <v-col>
        <GlobalBumpsResult :global="bumpsGlobalCount" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BumpsList :list="bumps" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
/* ul {
  margin-top: 20px;
} */
</style>
<script>
import BumpsList from '@/components/BumpsList/BumpsList';
import { mapState, mapGetters } from 'vuex';
import GlobalBumpsResult from '@/components/Results/GlobalBumpsResult';

export default {
  components: { BumpsList, GlobalBumpsResult },
  computed: {
    ...mapGetters('runs', ['bumpsGlobalCount']),
    ...mapState('runs', ['counts', 'bumps']),
  },
  created() {
    this.$store.dispatch('setPageName', 'Liste des bumps');
  },
};
</script>
