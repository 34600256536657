<template>
  <div class="run-summary-container">
    <v-row v-if="selectedRun">
      <v-col class="run-bloc">
        <span class="run-name">Course {{ selectedRun.name }}</span>
        <span class="rundates">
          du
          {{ dateStart | formatDate }} au {{ dateEnd | formatDate }}
        </span>
        <span class="status-bloc">
          <span :class="{ ongoing: isCurrent, dotstatus: true }"></span>
          <template v-if="isCurrent"> En cours </template>
          <template v-else>Terminée </template>
        </span>
      </v-col>
    </v-row>
    <v-row v-if="bumpsGlobalCount">
      <v-col cols="4" v-if="isCurrent">
        <div class="time-infos">
          <span class="time-label"> Reste</span>
          <template v-if="timeLeft">
            <span class="big-number" v-if="timeLeft && timeLeft.days > 1">
              {{ timeLeft.days }} j
            </span>
            <template v-else-if="timeLeft.days === 1">
              <span class="big-number">
                {{ timeLeft.days }}j{{ timeLeft.hours }}h
              </span>
              <!-- <span class="time-label"> et {{ timeLeft.hours }}h</span> -->
            </template>
            <span class="big-number" v-else-if="timeLeft.hours >= 1">
              {{ timeLeft.hours }}h{{ timeLeft.minutes }}
            </span>
            <span class="big-number" v-else-if="timeLeft">
              {{ timeLeft.minutes }}</span
            >
            <span v-else class="big-number">...</span>
          </template>
        </div>
      </v-col>
      <v-col cols="4" v-if="isCurrent">
        <div class="time-infos">
          <span class="time-label">encore</span>
          <span class="big-number">{{ bumpsLeft }}</span>
          <span class="time-label">bumps</span>
        </div>
      </v-col>
      <!-- <v-col cols="4">
        <div class="time-infos">
          <span class="time-label"> ça fait</span>
          <span class="big-number">{{ timePassed }}j</span>
          <span class="time-label"> qu'on joue</span>
        </div>
      </v-col> -->
      <v-col cols="4">
        <div class="time-infos">
          <span class="time-label">bumps / jour</span>
          <span class="big-number">{{ percentBumps }}</span>
          <span class="time-label">sur 12</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
    </v-row>

    <div class="right-bloc">
      <div></div>
      <div class="bloc"><GlobalBumpsResult :global="bumpsGlobalCount" /></div>
      <div class="bloc">
        <v-icon>mdi-human-handsdown</v-icon>
        {{ getBumperCount }} bumpers
        <v-icon>mdi-human-handsup</v-icon>
        {{ getClapisteCount }} clapistes
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.run-summary-container {
  .run-bloc {
    border-radius: 10px;
    // box-shadow: 0 7px 10px #80808021;
    background: #510051;
    color: white;
    position: relative;
    margin: 12px;
    box-shadow: -10px 10px 0 #e8e8e8;
    .status-bloc {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 0.8em;
      font-style: italic;
      .dotstatus {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ffc107;
        display: inline-block;
        &.ongoing {
          background: #8bc34a;
        }
      }
    }
  }
  .run-name {
    font-size: 1.4em;
    font-weight: bold;
    display: block;
  }
  .rundates {
    font-style: italic;
    font-size: 0.9em;
  }
  .bloc {
    border: 1px solid #e8e8e8;
    text-align: center;
    margin: auto;
    border-radius: 45px;
    padding: 7px 10px;
    margin-bottom: 10px;
    background: white;
    min-width: 250px;
    box-shadow: -5px 10px 0 #e8e8e8;
    margin-bottom: 20px;
  }

  .time-infos {
    background: #ffc107;
    width: 95px;
    height: 95px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    text-align: center;
    margin: auto;
    position: relative;
    box-shadow: -10px 10px 0 #e8e8e8;
    .big-number {
      font-size: 1.7em;
      font-weight: bold;
      position: relative;
      z-index: 3;
    }
    .time-label {
      font-size: 0.8em;
      font-weight: bold;
      line-height: 0.9em;
      position: relative;
      z-index: 3;
    }
  }
}
</style>
<script>
import { mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import GlobalBumpsResult from '@/components/Results/GlobalBumpsResult';

export default {
  components: { GlobalBumpsResult },
  computed: {
    ...mapState('runs', {
      selectedRun: (state) => state.selectedRun,
      dateStart: (state) =>
        state.selectedRun && state.selectedRun.dateStart.toDate(),
      dateEnd: (state) =>
        state.selectedRun && state.selectedRun.dateEnd.toDate(),
    }),
    ...mapState('auth', ['isLogged']),
    ...mapGetters('runs', [
      'getBumperCount',
      'getClapisteCount',
      'bumpsGlobalCount',
      'getLastSuccessfullBump',
      'isCurrent',
    ]),
    timeLeft() {
      if (!this.dateEnd) return null;
      const dayLeft = dayjs(this.dateEnd).diff(dayjs(), 'day');
      const hoursLeft = dayjs(this.dateEnd).diff(
        dayjs().add(dayLeft, 'day'),
        'hours'
      );
      const minutesLeft = Math.ceil(
        dayjs(this.dateEnd).diff(
          dayjs()
            .add(dayLeft, 'day')
            .add(hoursLeft, 'hour'),
          'minute',
          true
        )
      );
      // const dayLeft =dayjs(this.dateEnd).diff(dayjs(), 'day');
      return { days: dayLeft, hours: hoursLeft, minutes: minutesLeft };
    },
    timePassed() {
      return Math.round(dayjs(dayjs()).diff(this.dateStart, 'day', true));
    },
    percentBumps() {
      if (
        !this.bumpsGlobalCount ||
        this.bumpsGlobalCount === undefined ||
        !this.selectedRun
      )
        return null;
      const dateEnd = this.isCurrent ? dayjs() : this.dateEnd;
      let runLength = dayjs(dateEnd).diff(this.dateStart, 'day');
      if (runLength === 0) {
        runLength = 1;
      }
      return ((this.bumpsGlobalCount.success / (runLength * 12)) * 12).toFixed(
        1
      );
    },
    bumpsLeft() {
      if (!this.timeLeft || !this.getLastSuccessfullBump) return '?';
      const lastBump = dayjs(this.getLastSuccessfullBump.datetime.toDate());
      const hours = dayjs().diff(lastBump, 'hour', true);
      return Math.floor(
        (this.timeLeft.hours + hours) / 2 + this.timeLeft.days * 12
      );
    },
  },
};
</script>
