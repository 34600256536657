<template>
  <v-container>
    <v-row v-if="isLogged">
      <v-col>
        <RunSelector />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RunSelector from '@/components/Results/RunSelector.vue';
import { mapState } from 'vuex';
export default {
  name: 'App',
  // eslint-disable-next-line vue/no-unused-components
  components: { RunSelector },
  computed: {
    ...mapState('auth', ['isLogged']),
  },
};
</script>
