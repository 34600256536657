<template>
  <div class="summary-container">
    <ul>
      <li>
        <v-icon medium color="light-green"> mdi-robot-happy </v-icon>
        {{ global.success }}
      </li>
      <li>
        <v-icon medium color="red"> mdi-robot-angry </v-icon>
        {{ global.fail }}
      </li>
      <li>
        <v-icon medium color="orange"> mdi-incognito </v-icon>
        {{ global.troll }}
      </li>
      <li>
        <v-icon medium color="light-blue"> mdi-robot </v-icon>
        {{ global.troll + global.fail + global.success }}
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
</style>
<script>
export default {
  props: {
    global: {
      default() {
        return { success: 0, fail: 0, troll: 0 };
      },
    },
  },
};
</script>
<style lang="scss">
.summary-container {
  width: 100%;
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    li {
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .v-icon {
        margin-right: 5px;
      }
    }
  }
}
</style>
