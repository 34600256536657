import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import Admin from '../views/Admin.vue';
import Ranks from '../views/Ranks.vue';
import Players from '../views/Players.vue';
import Dashboard from '../views/Dashboard.vue';
import Bumps from '../views/Bumps.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { layout: 'admin' },
    component: Admin,
  },
  {
    path: '/bumps',
    name: 'Bumps',
    component: Bumps,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/ranks',
    name: 'Ranks',
    component: Ranks,
  },
  {
    path: '/players',
    name: 'Players',
    component: Players,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
