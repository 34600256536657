import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA1HO9m7zc1ptMf-e_5uR9Umj2ItESuLf4',
  authDomain: 'bumpwar-a19f5.firebaseapp.com',
  databaseURL: 'https://bumpwar-a19f5-default-rtdb.firebaseio.com',
  projectId: 'bumpwar-a19f5',
  storageBucket: 'bumpwar-a19f5.appspot.com',
  messagingSenderId: '12509933097',
  appId: '1:12509933097:web:c2d8f40674e18e4349b638',
};
// export const db = firebase.initializeApp(firebaseConfig).database();

export const db = firebase.initializeApp(firebaseConfig).firestore();
